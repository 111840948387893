<template>
  <div>
    <titulo-seccion>
      <template slot="area-rutas">
        <router-link to="/">Inicio</router-link> /
        <span class="active">Buzón Institucional</span>
      </template>
      <template slot="area-titulo">
        <h4>Buzón Institucional</h4>
      </template>
    </titulo-seccion>

    <div
      id="content-page"
      class="container bg-tertiary p-4 mt-4"
      :style="{
        '--background-color-tertiary': config.disenio.datos.color_terciario,
      }"
    >
      <div class="row">
        <div class="col-md-12">
          <p
            class="text-secondary text-center"
            :style="{
              '--color-secondary': config.disenio.datos.color_secundario,
            }"
          >
            Envíenos su documentación o correspondencia desde cualquier punto
            del país para la FISCALÍA GENERAL DEL ESTADO (NO VÁLIDO PARA
            PROCESOS PENALES)
          </p>
        </div>
        <div class="col-md-6 offset-lg-3">
          <b-form @submit="onSubmit" @reset="limpiarForm" v-if="show" validated>
            <b-form-group
              id="input-group-remitente"
              label="Nombre Remitente *"
              label-for="nombre-remitente"
              description=""
              class="mb-3"
            >
              <b-form-input
                id="nombre-remitente"
                v-model="form.nombre_remitente"
                type="text"
                placeholder="Escriba el nombre del remitente"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-correo"
              label="Correo Electrónico *"
              label-for="correo-electronico"
              class="mb-3"
            >
              <b-form-input
                id="correo-electronico"
                v-model="form.correo"
                type="email"
                placeholder="Escriba el correo"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-institucion"
              label="Institución"
              label-for="institucion"
              class="mb-3"
            >
              <b-form-input
                id="institucion"
                v-model="form.institucion"
                placeholder="Escriba el nombre de la Institución"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-referencia"
              label="Referencia *"
              label-for="referencia"
              class="mb-3"
            >
              <b-form-textarea
                id="referencia"
                v-model="form.referencia"
                placeholder="Escriba la referencia"
                required
              ></b-form-textarea>
            </b-form-group>

            <b-form-group
              id="input-group-adjunto"
              label="Adjuntar Archivo *"
              label-for="archivo"
              class="mb-3"
            >
              <b-form-file
                id="archivo"
                v-model="form.archivo"
                placeholder="Elija un archivo o suéltelo aquí..."
                plain
                accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              ></b-form-file>
            </b-form-group>
            <div  
              class="h-captcha d-flex justify-content-md-center p-4"
              :data-sitekey="siteKey"
              id="h-captcha"
            ></div>
            <div class="mt-2 d-flex justify-content-md-center">
              <b-button
                type="submit"
                class="me-2 btn btn-block btn-fiscalia"
                :style="{
                  '--background-color-btn': config.disenio.datos.color_primario,
                }"
                >Enviar</b-button
              >
              <b-button
                type="button"
                @click="limpiarForm"
                class="ms-2 btn btn-block btn-fiscalia"
                :style="{
                  '--background-color-btn':
                    config.disenio.datos.color_secundario,
                }"
                >Limpiar</b-button
              >
            </div>
          </b-form>
        </div>
      </div>
      <div id="navegacion" class="mt-5 mb-5 row"></div>
    </div>
    <div
      class="toast-container position-fixed bottom-0 start-50 translate-middle-x p-3"
    >
      <div
        id="myToast"
        class="toast text-white"
        :class="dataToast.bg"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div class="toast-header">
          <strong class="me-auto">{{ dataToast.title }}</strong>
          <small>Justo ahora</small>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="toast"
            aria-label="Close"
          ></button>
        </div>
        <div class="toast-body">{{ dataToast.message }}</div>
        <div v-if="respuestaBuzon && respuestaBuzon.errors" class="px-3">
          <li
            v-for="(errorMessages, field) in respuestaBuzon.errors"
            :key="field"
          >
            <strong>{{ field }}:</strong>
            <ul>
              <li v-for="message in errorMessages" :key="message">
                {{ message }}
              </li>
            </ul>
          </li>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosIns from "@/libs/axios";
import TituloSeccion from "../secciones/TituloSeccion.vue";

import NProgress from "nprogress";
import { Toast } from "bootstrap";
export default {
  components: {
    TituloSeccion,
  },

  metaInfo: {
    title: "Ministerio Público",
    titleTemplate: "%s | Buzón Institucional",
    htmlAttrs: {
      lang: "es-ES",
    },
  },

  data() {
    return {
      form: {
        nombre_remitente: "",
        correo: "",
        institucion: "",
        referencia: "",
        archivo: null,
        recaptchaInstance: null,
      },
      responseCaptcha: null,
      respuestaBuzon: null,
      show: true,
      dataToast: {
        title: "",
        message: "",
        bg: "",
      },
    };
  },
  created() {},
  mounted() {
    if (
      !document.querySelector("script[src='https://js.hcaptcha.com/1/api.js']")
    ) {
      const script = document.createElement("script");
      script.src = "https://js.hcaptcha.com/1/api.js";
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    } else {
      this.onScriptLoad();
    }
  },
  computed: {
    siteKey() {
      return process.env.VUE_APP_KEY_CAPTCHA;
    },
    config() {
      return this.$store.state.config;
    },
  },
  watch: {},
  beforeDestroy() {},
  methods: {
    onSubmit(event) {
      event.preventDefault();
      this.respuestaBuzon = null;
      const hcaptchaResponse = window.hcaptcha.getResponse();
      if (!hcaptchaResponse) {
        this.showToast(
          "Error",
          "Por favor, resuelve el reCAPTCHA.",
          "bg-danger"
        );

        return;
      }
      //alert(JSON.stringify(this.form));
      var url = "/web/send-buzon-mail";
      const formData = new FormData();
      formData.append("nombre_remitente", this.form.nombre_remitente);
      formData.append("correo", this.form.correo);
      formData.append("institucion", this.form.institucion);
      formData.append("referencia", this.form.referencia);
      formData.append("files", this.form.archivo);

      axiosIns
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res.data);
          if (!res.data.data.error) {
            this.showToast("Enviado", res.data.data.message, "bg-success");
          } else {
            this.showToast("Error", res.data.data.message, "bg-danger");
          }

          this.limpiarForm();
        })
        .catch((err) => {
          // Inspecciona todas las propiedades posibles
          NProgress.done();
          this.respuestaBuzon = {
            mensaje: "",
            class: "",
            errors: {},
          };
          this.respuestaBuzon.mensaje = err.message;
          this.respuestaBuzon.class = "danger";

          if (err.response) {
            this.respuestaBuzon.errors = err.response.data.errors;
            console.log("Error Data:", err.response.data);
          }

          this.showToast("Error", err.message, "bg-danger");
        });
    },
    onScriptLoad() {
      console.log("reCAPTCHA script cargado");
    },
    limpiarForm() {
      this.form.nombre_remitente = "";
      this.form.correo = "";
      this.form.institucion = "";
      this.form.referencia = "";
      this.form.archivo = null;
    },

    handleVerify(response) {
      this.responseCaptcha = response;
    },
    showToast(title, message, bg) {
      this.dataToast = {
        title: title,
        message: message,
        bg: bg,
      };
      const toastElement = document.getElementById("myToast");
      const toast = new Toast(toastElement, {
        autohide: true, // Se ocultará automáticamente después de un tiempo
        delay: 5000, // El tiempo de visualización en milisegundos
      });
      toast.show();
    },
  },
};
</script>

<style scoped>
label {
  margin-bottom: 1rem !important;
}
.toast-container {
  z-index: 1050; /* Asegúrate de que el toast esté por encima de otros elementos */
}
</style>
